<template>
    <div class="Contact pt-4 pb-4">
        <h2 class=" fs-1">Lets Chat!</h2>
        <div class="container pt-2 pb-2">
            <a href="../Contact">Get In Touch</a>
        </div>
    </div>
</template>


<style lang="scss" scoped>
h2{
    text-align: center;
    font-weight: bold;
    color: #636250;
}
a{
    text-decoration: none;
    color:#a19a90;
    font-size:16pt;
    &:hover {
        color: darken(#a19a90,20%);
    }
}


.Contact{
   background-color: #b7ced2;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23dcdde2' fill-opacity='0.29' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    text-align: center;
}


</style>