<template>
  <Banner/>
  
  <br>
  <Projects/>
  <br/>
  <ContactBanner/>
  <br/>
  <MySkills/>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Banner.vue";
import MySkills from '../components/MySkills.vue';
import Projects from '../components/ProjectsSmallList.vue';
import ContactBanner from "../components/ContactBanner.vue";

export default {
  name: "Home",
  components: {
    Banner,
    MySkills,
    Projects,
    ContactBanner
  },
};
</script>
