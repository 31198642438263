<template>
    <div class="Projects pt-2">
        <h2 class=" fs-1">Upcoming Projects</h2>
        <hr>
        <div class="container pt-2 pb-3">
            <div class="row">
                <div class="col p-2">
                    <h3>Fantasy Book Title Generator</h3>
                    <p>A project to create a randomly strung together title for fantasy novels.</p>
                </div>
                <div class="col p-2">
                    <h3>Family Cookbook </h3>
                    <p>Organize and create cookbooks with your family and friends</p>
                </div>
                <div class="col p-2">
                    <h3>Yarny </h3>
                        <p>A project dedicated to keeping track of your knit and crochet projects and stashes </p>
                </div>
            </div>   
            <div class="row">
                <a href="/projects" >See More Projects</a>
            </div>
        </div>
    </div>
</template>


<style scoped>
h2{
    text-align: center;
    font-weight: bold;
    color: #b7ced2;
}
h3{
    text-align: center;
    font-weight: bold;
    color:#636250;
}
p{
    color:#a19a90;
}

  a {
  padding: 12px;
  border: 2px solid #a19a90;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  text-decoration: none;
  color:#636250;
  text-align: center;
  
}



a:hover {
  color:white;
    background-color: #a19a90;
}

</style>