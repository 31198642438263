import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "@/sass/theme.scss";


import { library } from '@fortawesome/fontawesome-svg-core';
// import { } from '@fortawesome/free-solid-svg-icons';
// import { } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin, faJava, faGithub, faGitlab, faVuejs, faJs } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//Add all icons to the library so you can use it in your page
library.add(faLinkedin);
library.add(faGithub);
library.add(faJava);
library.add(faGitlab);
library.add(faVuejs);
library.add(faJs);

createApp(App)
.use(store)
.use(router)
.component("font-awesome-icon", FontAwesomeIcon)
.mount("#app");