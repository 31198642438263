<template>
    <div class="SkillsList pb-5">
        <h1 class="fs-1">My Skills</h1>
        <hr/>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    C#
                </div>
                <div class="col-sm">
                    .NET
                </div>
                <div class="col-sm">
                    .NET Core
                </div>
            </div>  
            <br/>
            <div class="row">
                <div class="col-sm">
                    Java
                </div>
                <div class="col-sm">
                    HTML
                </div>
                <div class="col-sm">
                    Vue.js
                </div>
            </div> 
        </div>
    </div>
</template>



<style scoped lang="scss">

h1{
  padding-top:10px;
  font-weight: bold;
  text-align: center;
  color: #b7ced2;
}
hr{
  border-top: 1px dotted #a19a90;
  border-radius: 5px;
}
.container{
    color: #a19a90;
    font-weight: bold;
}
</style>