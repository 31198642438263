<template>
  <div class="header">
    <img src="@/assets/Wave.jpg">
      <h1 class=" top-left fst-italic fs-1">Hi, I'm Spencer</h1>
      <br/>
      <div class="tagline">
        <p>A software developer looking to learn new things!</p>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  text-align: center;
  color: white;
}
.top-left{
  position: absolute;
  top: 60px;
  left: 80px;
}
.tagline{
   position: absolute;
  bottom: 8px;
  right: 16px;
}

img{
  
  position: relative;
    max-width: 100%;
}

.hello{
  padding-top: 40px;
  padding-right:60px;
}
h1{
  font-weight: bold;
  z-index:100;
}

</style>
