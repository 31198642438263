<template>
  <div>
    <div class="container">
      <div class = "row">
        <div class = "col-auto me-auto">
          <div id="homeNav">
            <router-link to="/">Home</router-link>
          </div>
        </div>
        <div class = "col-auto">
          <div id="nav">
            <router-link to="/about">About Me</router-link> | 
            <router-link to="/projects">Projects</router-link> | 
            <router-link to="/contact">Contact Me</router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <footer class="pt-2 pb-2">
      <div class="container-fluid">
        <div class = " row">
          <div class="p-2 bottomNav col-auto">
              <router-link to="/about">About Me</router-link> | 
              <router-link to="/projects">Projects</router-link> | 
              <router-link to="/contact">Contact Me</router-link>
          </div>
          <div class="Socials p-2 col-auto ms-auto">
            <a class="social pe-2" href="https://www.linkedin.com/in/spencer-r-mcdonald"><font-awesome-icon :icon="['fab', 'linkedin']"/></a>
            <a class="social pe-2" href="https://github.com/SpencerRMcDonald"><font-awesome-icon :icon="['fab', 'github']" /></a>
            <a class="social pe-2" href="https://gitlab.com/TheReecespieces"><font-awesome-icon :icon="['fab', 'gitlab']" /> </a>
          </div>
        </div>
      </div>
    </footer> 
  </div>
</template>

<script>
 
</script>

<style lang="scss">
#app {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #636250;
}

h1{
  color: #528e86;
}

#nav  {
  text-align: right;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right:50px;
  a {
    font-weight: bold;
    color: #a19a90;
    text-decoration: none;

    &.router-link-exact-active {
      color: #636250;
    }
  }
}


#homeNav{
  text-align:left;
  padding-top: 10px;
  padding-bottom: 10px;
  a {
    font-weight: bold;
    color: #a19a90;
    
    text-decoration: none;

    &.router-link-exact-active {
      color: #636250;
    }
  }
}
footer{
   background-color: #528e86;
   width:100%;

   
}

.bottomNav{
  a {
    font-weight: bold;
    color: #b7ced2;;
    text-decoration: none;

    
  &:hover{
    color: lighten(#b7ced2, 20%);
  }
  }
}



.social svg{
  height: 30px;
  text-decoration:none;
  color: #b7ced2;

  &:hover{
    color: lighten(#b7ced2, 20%);
  }
}

</style>
